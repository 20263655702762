import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"
import { useMediaPredicate } from "react-media-hook"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
import { Card } from "../../components/card/card"
import { Section } from "../../components/grid/grid"
import { TabLink } from "../../components/tab-header/tab-header"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_STATIC_DATA = graphql`
  query {
    imgCoverMain: file(relativePath: { eq: "news-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allNewsJson {
      edges {
        node {
          newsItems {
            id
            category
            title
            excerpt
            date(formatString: "MMM DD, YYYY")
            url
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    wordPress {
      categories {
        nodes {
          name
          slug
          posts(first: 1000) {
            nodes {
              id
              slug
              title
              date
              featuredImage {
                sourceUrl
              }
              categories {
                nodes {
                  name
                  slug
                }
              }
              translation(language: ID) {
                id
                slug
                title
                date
                featuredImage {
                  sourceUrl
                }
                categories {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }
          }
        }
      }
      page(id: "news-2", idType: URI) {
        title
        slug
        blocks {
          name
          ... on WordPress_CoreCoverBlock {
            attributes {
              url
              className
            }
          }
        }
        translation(language: ID) {
          title
          slug
          blocks {
            name
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          opengraphTitle
          opengraphDescription
        }
      }
      allNews: posts(first: 1000, where: { language: EN }) {
        nodes {
          id
          slug
          title
          date
          featuredImage {
            sourceUrl
          }
          categories {
            nodes {
              name
              slug
            }
          }
          translation(language: ID) {
            id
            slug
            title
            date
            featuredImage {
              sourceUrl
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`

const NewsItems = ({ data, defaultImage, label, state }) => {
  return data ? (
    data.posts.nodes.map((item, i) => {
      if (i > state * 10 - 10 && i < state * 10 || i === 0) {
        return (
          <Card
            id={item.id}
            key={item.id}
            variant="basic"
            colLeft="col-md-5"
            colRight="col-md-7"
            label={label || "Blog"}
            title={item?.translation?.title || item?.title}
            // excerpt={item.excerpt}
            text={moment(item.date).format("DD/MM/YYYY")}
            img={
              item.featuredImage ? item.featuredImage.sourceUrl : defaultImage
            }
            imgHeight="h-ratio-3-2"
            link={`/id/news/${item.translation?.slug || item.slug}`}
            className="card-post pb-3"
          />
        )
      }
    })
  ) : (
    <div className="py-main"></div>
  )
}

const News = ({ location }) => {
  const [eventsOffset, setEventsOffset] = useState(1)

  const staticData = useStaticQuery(GET_STATIC_DATA)

  const eventsPrograms = staticData.wordPress.categories.nodes.find(
    x => x.slug === "events-programs"
  )

  const wordPress =
    staticData?.wordPress?.page?.translation || staticData?.wordPress?.page
  const seo =
    staticData?.wordPress?.page?.translation?.seo ||
    staticData?.wordPress?.page?.seo

  const cover =
    wordPress && wordPress?.blocks?.find(item => item.name === "core/cover")
  
  let coverItems = []

  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
    return (
      <div className="d-flex justify-content-end">
        <div className="paginate">
          <button
            className="btn btn-primary pagination-button"
            disabled={hasPreviousPage}
            onClick={prevPage}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <button
            className="btn btn-primary pagination-button"
            disabled={hasNextPage}
            onClick={nextPage}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    )
  }

  const eventsNextPage = () => {
    if (eventsPrograms.posts.nodes[eventsOffset * 10] !== undefined) {
      setEventsOffset(eventsOffset + 1)
    }
  }
  const eventsPrevPage = () => {
    if (eventsOffset >= 1) {
      setEventsOffset(eventsOffset - 1)
    }
  }

  // eslint-disable-next-line
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({url: item?.attributes?.url, className: item?.attributes?.className})
    }
  })

  useEffect(() => {
    // eslint-disable-next-line
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({url: item?.attributes?.url, className: item?.attributes?.className})
      }
    })
  }, [])

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={cover && cover.attributes.url}
        twitterImg={cover && cover.attributes.url}
        img={cover && cover.attributes.url}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={wordPress?.title}
        img={ resizeScreen ? coverItems[1]?.url : coverItems[0]?.url }
        className={`cover-md cover-detail ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className}`}
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>

      <Section className="py-0">
        <TabLink
          itemCenter
          mobileCol={5}
          textCenter
          path="/id/news/events"
          data={[
            { name: "Semua", target: "/id/news" },
            { name: "Blog", target: "/id/news/blog" },
            { name: "Press Release", target: "/id/news/press-release" },
            {
              name: "Event & Program",
              target: "/id/news/events",
            },
            { name: "Sustainability News", target: "/id/news/sustainability" },
          ]}
        />
        <div className="tab-content">
          <section id="newsBlog" className="section-news py-main" role="tabpanel">
            <div className="container">
              <NewsItems
                label="Events & Programs"
                data={eventsPrograms}
                state={eventsOffset}
                defaultImage={staticData.imgCoverMain.childImageSharp.fluid}
              />
              {eventsPrograms.posts.nodes.length > 0 && (
                <div className="mb-5">
                  <Pagination
                    hasPreviousPage={eventsOffset === 1}
                    hasNextPage={
                      eventsPrograms.posts.nodes[eventsOffset * 10 + 1] ===
                      undefined
                    }
                    prevPage={eventsPrevPage}
                    nextPage={eventsNextPage}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
      </Section>
    </Layout>
  )
}

export default News
